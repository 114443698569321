import React, { useContext } from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getNewsPath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink } from "../../utils/link"
import { render } from "datocms-structured-text-to-plain-text"
import LongBlueArrow from "../../assets/images/arrow_blu.inline.svg"
import { MenuFooterType } from "../../context/menuFooterType"

const NewsThumb = ({ news, small }) => {
  const menuType = useContext(MenuFooterType).type
  const currentPastMonth = useContext(MenuFooterType).currentPastMonth

  const componentTheme =
    menuType === "month" ? (currentPastMonth ? "light" : "dark") : "dark"
  const dark = componentTheme === "light" ? "dark" : "background"

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <InboundLink
        to={getNewsPath(news, news.locale)}
        sx={{ textDecoration: "none", fontSize: [2], color: componentTheme }}
      >
        <Grid columns={[1, small ? 1 : "5fr 3fr"]} gap={0}>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Heading
              as="h2"
              variant="h3"
              sx={{ mb: 0, mt: 3, color: "primary", fontSize: [4, "42px"] }}
            >
              {news.meta.firstPublishedAt}
            </Heading>
            <Heading
              as="h2"
              variant="h3"
              sx={{ mb: 3, mt: 0, color: componentTheme, fontSize: [4, "42px"] }}
            >
              {news.title}
            </Heading>
            <Text as="div" sx={{ my: [2, 3] }}>
              {news.body.value && render(news.body)
                ? render(news.body).substring(0, 240) + " ..."
                : ""}
            </Text>
          </Flex>
        </Grid>
        <Flex sx={{ mt: [3] }}>
          <Box sx={{ mr: [2], fontWeight: "bold" }}>SCOPRI DI PIÙ</Box>
          <Box
            sx={{
              svg: {
                svg: {
                  fill: "text",
                  stroke: "text",
                },
              },
            }}
          >
            <LongBlueArrow />
          </Box>
        </Flex>
      </InboundLink>
    </Box>
  )
}

export default NewsThumb
