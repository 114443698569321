import React from "react"
import { Box, Container } from "theme-ui"
import LatestNews from "../LatestNews"

const NewsCollection = ({ block, news }) => {
  const { title } = block

  return (
    news.length >0 &&
    <Box>
        <LatestNews news={news} title={title} />
    </Box>
  )
}
export default NewsCollection
