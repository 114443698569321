import React from "react"
import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { Box, Text, Container,Heading} from "@theme-ui/components"
import Layout from "../layouts/Layout"
import { getNewsPath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import NewsTitle from "../components/NewsTitle"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import ImageGallery from "../components/ImageGallery"
import NewsCollection from "../components/NewsCollection"

const News = ({ data: { page, news } }) => {

  const favicon = useFavicon().site.faviconMetaTags

  // console.log(page)
  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getNewsPath(page, path.locale),
    }
  })

  
  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <NewsTitle page={page} />
      <Container variant="md" sx={{ py: [0, 0] }}>
        <StructuredText
          data={page.body}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Heading
                        as={`h${node.level}`}
                        variant={`article.h${node.level}`}
                        sx={{ mb: 3, mt: [3] }}
                      >
                        {children}
                      </Heading>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text as="p" variant="p">
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
          renderBlock={({ record }) => {
            // console.log(record)
            switch (record.__typename) {
              case "DatoCmsImageGallery":
                return (
                  <Box mt={5} mb={5}>
                    <ImageGallery images={record.images} key={record.id} />
                  </Box>
                )
              default:
                return null
            }
          }}
        />
      </Container>
      {news.nodes.length > 0 && (
        <NewsCollection
          block={{
            title: "<h2><em>notizie. </em>resta aggiornato.</h2>",
          }}
          news={news.nodes.filter(x => x.id != page.id)}
        />
      )}
    </Layout>
  )
}

export default News

export const query = graphql`
  query NewsQuery($id: String!, $locale: String!) {
    page: datoCmsNews(id: { eq: $id }) {
      ...NewsDetails
      ...NewsAllSlugLocales
      ...NewsMeta
      meta {
        firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    news: allDatoCmsNews(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 3
    ) {
      nodes {
        ...NewsDetails
        ...NewsAllSlugLocales
        ...NewsMeta
      }
    }
  }

  fragment NewsMeta on DatoCmsNews {
    meta {
      firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
    }
  }

  fragment NewsAllSlugLocales on DatoCmsNews {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment NewsDetails on DatoCmsNews {
    id
    locale
    title
    slug
    model {
      apiKey
    }
    heroImage {
      gatsbyImageData(
        width: 1480
        height: 986
        placeholder: BLURRED
        forceBlurhash: false
      )
    }
    ...NewsBody
  }

  fragment NewsBody on DatoCmsNews {
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
      }
    }
  }

  fragment ImageGallery on DatoCmsImageGallery {
    images {
      gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          fit: "crop"
          ar: "16:9"
          h: "620"
        }
      )
      alt
      title
    }
    model {
      apiKey
    }
  }
`
