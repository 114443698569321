import React from "react"
import { Box, Text, Container, Heading, Flex } from "@theme-ui/components"
import Breadcrumbs from "../Breadcrumbs"
import { InboundLink } from "../../utils/link"
import { getBlogPath } from "../../utils/path"
import LongBlueArrow from "../../assets/images/arrow_blu.inline.svg"

const NewsTitle = ({ page }) => {
  return (
    <Container variant="md" sx={{pb:[0,0]}}>
      <Box sx={{my:[4,7,7]}}>
        <InboundLink
          to={getBlogPath(page.locale)}
          sx={{
            display: "inline-block",
            transform: "rotate(180deg)",
          }}
          title="Indietro"
        >
          <LongBlueArrow />
        </InboundLink>
      </Box>
      {page.meta && page.meta.firstPublishedAt && (
        <Box>
          <Text
            as="p"
            variant="caption"
            sx={{
              textAlign: "left",
              fontSize: [5, 9],
              textAlign: "left",
              color: "primary",
            }}
          >
            {page.meta.firstPublishedAt}
          </Text>
        </Box>
      )}
      <Heading
        as="h1"
        variant="h3"
        sx={{ fontSize: [6, 9], my: [0, 0], textAlign: "left" }}
      >
        {page.title}
      </Heading>
    </Container>
  )
}

export default NewsTitle
